import styled from 'styled-components';

export const TopBarStyles = styled.div`
  background-color: var(--green);
  background-image: linear-gradient(to left, var(--green), var(--dgreen));

  ul {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    margin: 0;
    height: 48px;
    margin: 0;
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
  }

  li {
    margin-left: 20px;
    color: white;

    &:last-child {
      margin-right: 0;
    }
  }

  .button {
    font-size: 12px;
    padding: 0.4rem 1rem;
  }
`;

export const NavStyles = styled.div`
  border-bottom: 1px solid var(--grey);
  position: relative;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;

  .nav-wrapper {
    max-width: 1200px;
    padding: 0 20px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    @media (max-width: 800px) {
      flex-wrap: wrap;
      height: 80px;
    }
  }

  .logo {
    margin-right: auto;
    padding: 10px 0;
  }
`;

export const MenuToggleStyle = styled.button`
  background: transparent;
  padding: 0;

  &:hover {
    background: transparent;
  }

  @media (min-width: 801px) {
    display: none;
  }

  &.open {
    span {
      &:last-child {
        display: none;
      }

      &:first-child {
        transform: translate(-10px) rotate(45deg) translate(15px);
      }

      &:nth-child(2) {
        transform: translate(-10px) rotate(-45deg) translate(15px);
        margin-top: 15px;
      }
    }
  }

  span {
    display: block;
    margin: 4px;
    height: 5px;
    width: 32px;
    background: var(--green);
    border-radius: 9px;
    transition: transform 0.2s ease;
  }
`;

export const MenuStyles = styled.nav`
  @media (max-width: 800px) {
    background: var(--dgrey);
    position: absolute;
    right: -100%;
    width: 100%;
    min-height: 100vh;
    top: 80px;
    z-index: 10;
    transition: right 0.2s ease;
    border-top: 5px solid var(--green);

    &.open {
      right: 0;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;

    @media (min-width: 801px) {
      display: flex;
    }
  }

  li {
    @media (max-width: 800px) {
      text-align: center;
      border-top: 1px solid var(--grey);
      color: white;

      a {
        padding: 15px;
        color: white;

        &:hover {
          color: var(--green);
        }
      }
    }

    @media (min-width: 801px) {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .logo {
    margin-right: auto;
  }

  a {
    font-size: rem-calc(16);
    text-decoration: none;
    display: block;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      color: var(--green);
    }

    &[aria-current='page'] {
      color: var(--green);
    }
  }
`;
