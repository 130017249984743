import React from 'react';
import styled from 'styled-components';
import Nav from './Nav';
import Footer from './Footer';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import CTA from './CTA';


const ContentStyles = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Nav />
      <ContentStyles>{children}</ContentStyles>

      <CTA />
      <Footer />
    </>
  );
}
