import React from 'react';
import styled from 'styled-components';

const CTAStyles = styled.div`
  background-color: var(--green);
  background-image: linear-gradient(to left, var(--green), var(--dgreen));
  padding: 60px 20px;
  text-align: center;
  color: white;

  h3 {
    font-size: 42px;
    font-weight: bold;
    line-height: 49px;
  }

  p {
    margin-bottom: 20px;
  }

  a {
    margin: 0;
  }
`;

export default function CTA() {
  return (
    <CTAStyles>
      <h3>Call us today</h3>
      <p>We are here for all of your plumbing needs!</p>
      <a href="tel:07538008378" className="button">
        Call now: 07538 008 378
      </a>
    </CTAStyles>
  );
}
