import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  FaFacebookSquare,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa';
import React from 'react';
import styled from 'styled-components';
import BackToTop from './BackToTop';
import Logo from './Logo';

const FooterTop = styled.div`
  background: #f8f9fa;
`;

const FooterTopContent = styled.div`
  max-width: 1200px;
  padding: 40px 20px;
  margin: 0 auto;

  .footer-logo {
    width: 50%;

    @media (min-width: 801px) {
      width: 180px;
    }
  }

  .footer-top-content {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 801px) {
      flex-wrap: nowrap;
    }
  }

  .footer-top-content-section {
    width: 100%;
    margin-bottom: 20px;

    @media (min-width: 801px) {
      width: calc(100% / 3);
    }

    h3 {
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    a {
      font-size: 16px;
      line-height: 23px;
      text-decoration: none;
      color: #333;

      &:hover {
        color: var(--green);
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 12px 0;
    padding: 0;
  }
`;

const FooterBottom = styled.div`
  background: #3f3f3f;
  padding: 16px 0;
`;

const FooterBottomContent = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 14px;
  color: var(--grey);
  text-align: center;
`;

const SocialLinkStyles = styled.ul`
  display: flex;
  margin: 8px 0;

  li {
    margin-right: 20px;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .facebook {
    svg {
      fill: #3b5998;
    }
  }

  .twitter {
    svg {
      fill: #1da1f2;
    }
  }

  .instagram {
    svg {
      fill: #000;
    }
  }

  .linkedin {
    svg {
      fill: #1da1f2;
    }
  }
`;

const ContactLinkStyles = styled.ul`
  margin: 8px 0 0;

  li {
    font-size: 16px;

    a {
      display: block;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
`;

export default function Footer() {
  const { site, services } = useStaticQuery(graphql`
    query {
      site: sanitySiteSettings {
        menu {
          slug {
            current
          }
          title
        }
        contact {
          slug {
            current
          }
        }
        logo {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      services: allSanityService {
        nodes {
          title
          id
          slug {
            current
          }
          description
          image {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <footer>
      <FooterTop>
        <BackToTop />
        <FooterTopContent>
          <div className="footer-top-content">
            <div className="footer-top-content-section">
              <div className="footer-logo">
                <Link to="/">
                  <Logo image={site.logo} />
                </Link>
              </div>
              <ContactLinkStyles>
                <li>
                  <span>Call us on:</span>
                  <a href="tel:07538008378">07538008378</a>
                </li>
                <li>
                  <span>Or email us at:</span>
                  <a href="mail:emeraldheating20@gmail.com">
                    emeraldheating20@gmail.com
                  </a>
                </li>
              </ContactLinkStyles>

              <SocialLinkStyles>
                <li>
                  <a
                    href="https://www.facebook.com/Emeraldheating20/"
                    rel="nofollow"
                    title="Facebook"
                    className="facebook"
                  >
                    <FaFacebookSquare />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/emeraldheating20/"
                    rel="nofollow"
                    title="Instagram"
                    className="instagram"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://uk.linkedin.com/in/adam-haill-879a48b9"
                    rel="nofollow"
                    title="LinkedIn"
                    className="linkedin"
                  >
                    <FaLinkedin />
                  </a>
                </li>
              </SocialLinkStyles>
            </div>
            <div className="footer-top-content-section">
              <h3>Services</h3>
              <ul>
                {services.nodes.map((service) => (
                  <li key={service.id}>
                    <Link to={`/services/${service.slug.current}`}>
                      {service.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-top-content-section">
              <h3>Navigate</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                {site.menu.map((item) => (
                  <li key={item.slug.current}>
                    <Link to={`/${item.slug.current}`}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </FooterTopContent>
      </FooterTop>
      <FooterBottom>
        <FooterBottomContent>
          <p>
            &copy; {new Date().getFullYear()} Emerald Heating | All rights
            reserved.
          </p>
        </FooterBottomContent>
      </FooterBottom>
    </footer>
  );
}
