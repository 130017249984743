import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Logo from './Logo';
import {
  MenuStyles,
  MenuToggleStyle,
  NavStyles,
  TopBarStyles,
} from '../styles/MenuItemStyles';

export default function Nav() {
  const [open, setOpen] = React.useState(false);

  const { site } = useStaticQuery(graphql`
    query {
      site: sanitySiteSettings {
        menu {
          slug {
            current
          }
          title
        }
        contact {
          slug {
            current
          }
        }
        logo {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <TopBarStyles>
        <ul>
          <li>
            <a href="tel:07538008378" className="button">
              Call now
            </a>
          </li>
          <li>
            <Link to={`/${site.contact.slug.current}`} className="button">
              Get in touch
            </Link>
          </li>
        </ul>
      </TopBarStyles>
      <NavStyles>
        <div className="nav-wrapper">
          <div className="logo">
            <Link to="/">
              <Logo image={site.logo} />
            </Link>
          </div>
          <MenuToggleStyle
            onClick={() => {
              setOpen(!open);
            }}
            aria-label="Menu toggle"
            className={open ? 'open' : null}
          >
            <span />
            <span />
            <span />
          </MenuToggleStyle>
          <MenuStyles className={open ? 'open' : null}>
            <ul>
              <li>
                <Link
                  to="/"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Home
                </Link>
              </li>
              {site.menu.map((item) => (
                <li key={item.slug.current}>
                  <Link
                    to={`/${item.slug.current}`}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </MenuStyles>
        </div>
      </NavStyles>
    </>
  );
}
