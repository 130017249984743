import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const LogoStyles = styled.div`
  min-width: 100px;

  @media (min-width: 801px) {
    min-width: 180px;
  }
}`;

export default function Logo({ image }) {
  return (
    <LogoStyles className="logo">
      <Img fluid={image?.asset?.fluid} alt="Emerald Heating" />
    </LogoStyles>
  );
}
