import React from 'react';
import styled from 'styled-components';

const BackToTopStyles = styled.div`
  background: #f8f9fa;
  text-align: center;
  color: #ccc;
  padding: 16px 0;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);

  @media (min-width: 801px) {
    font-size: 16px;
  }
`;

export default function BackToTop() {
  const ScrollTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return <BackToTopStyles onClick={ScrollTop}>Back to top</BackToTopStyles>;
}
